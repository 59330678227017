import { useState } from "react";
import { SelectOption, SelectSearch } from "../common/forms";
import QueryView from "../common/lib/QueryView";
import SlideOutButton from "../common/SlideOutButton";
import useBusinesses from "../requests/useBusinesses";
import useValidateForFacilityAccess from "../requests/useValidateForFacilityAccess";

interface ScanButtonProps {
  stagedOrderId: string;
}

const ScanButton = ({ stagedOrderId }: ScanButtonProps) => {
  const [siteId, setSiteId] = useState<string | undefined>(undefined);

  const useBusinessesQuery = useBusinesses();

  const validateForFacilityAccess = useValidateForFacilityAccess(stagedOrderId, siteId!);

  return <QueryView
    query={useBusinessesQuery}
    renderData={businesses => <>
      <SlideOutButton
        text="Scan for Facility Access"
        disabled={siteId == undefined}
        onClick={() => validateForFacilityAccess.request({})} />
      <SelectSearch
        id='site'
        className='dropdown-item pt-0 mb-2'
        placeholder='Site for facility access...'
        options={businesses.flatMap(b => b.sites.map<SelectOption>(s => ({ value: s.id, name: s.city + ", " + s.state + " (" + b.name + ")" })))}
        onChange={id => setSiteId(id)}/>
    </>}
  />
}

export default ScanButton;
