import { HttpMethod } from "../common/lib/fetch/useFetch";
import useRequest, { RequestOptions } from "../common/lib/fetch/useRequest";

const useDispensingCompleted = (stagedOrderId: string, options?: RequestOptions) =>
  useRequest<void>({
    headers: () => Promise.resolve(new Headers()),
    method: HttpMethod.POST,
    path: `/api/developer/stagedOrders/${stagedOrderId}/dispensingCompleted`
  }, options);

export default useDispensingCompleted;
