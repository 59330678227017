import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useContext, useState } from 'react';
import useDisplayMessage from './common/lib/hooks/useDisplayMessage';
import KaAlert from './common/lib/KaAlert';
import SpinnerButton from './common/SpinnerButton';
import { driverFacingErrorMessage } from './common/userFacingMessages/userFacingMessages';
import useAcceptUserAgreement from './requests/useAcceptUserAgreement';
import { UserContext, User, UserStatus } from './userAccess/UserContext';

const UserAgreementModal = () => {
  const { _ } = useLingui();
  const userStatus = useContext<UserStatus>(UserContext);
  const user = userStatus as User;
  const [showModal, setShowModal] = useState(!user?.hasAcceptedCurrentUserAgreement);
  const [buttonsSpinning, setButtonsSpinning] = useState(false);
  const displayMessage = useDisplayMessage();

  const acceptUserAgreement = useAcceptUserAgreement({
    onSuccess: () => {
      window.location.reload();
    },
    onError: err => {
      setButtonsSpinning(false);
      displayMessage.fail(_(driverFacingErrorMessage(err)))
    },
  });

  const userAgreementAccepted = async () => {
    setButtonsSpinning(true);
    await acceptUserAgreement.request()
  };

  const userAgreementDeclined = () => {
    user.signOut();
  };

  if (!showModal) return null;

  return <>
    <div className="modal fade show d-block" tabIndex={-1} role="dialog" aria-labelledby="userAgreementLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header justify-content-center">
            <h2 className="modal-title ka-blue" id="userAgreementLabel"><Trans>Terms and Conditions</Trans></h2>
          </div>
          <div className="modal-body text-center" style={{ fontSize: '22px' }}>
            <KaAlert
              displayMessage={displayMessage.message}
              onClose={displayMessage.clear} />
            <p>
              <Trans>By clicking/tapping "<span className="ka-blue"><strong>Accept Terms</strong></span>," you confirm that you have read and agree to be bound by our <span className="ka-blue"><strong>Terms and Conditions</strong></span>.</Trans>
            </p>
            <a href={user.userAgreementUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}>
              <Trans>Terms &mdash; Kahler Automation</Trans>
            </a>
          </div>
          <div className="modal-footer justify-content-center" style={{ borderTop: 'none', padding: '8px 6px' }}>
            <div className="me-auto">
              <SpinnerButton 
                className="btn btn-link"
                onClick={userAgreementDeclined}
                spinning={buttonsSpinning}
                style={{ fontSize: '15px' }}>
                <strong><Trans>Exit LOADPASS</Trans></strong>
              </SpinnerButton>
            </div>
            <div className="ms-auto">
              <SpinnerButton
                className="btn btn-link"
                onClick={userAgreementAccepted}
                spinning={buttonsSpinning}
                style={{ fontSize: '15px' }}>
                <strong><Trans>Accept Terms</Trans></strong>
              </SpinnerButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal-backdrop fade show" />
  </>
};
export default UserAgreementModal;