import { msg, Plural, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useState, useEffect } from "react";
import CountUp from "react-countup";
import Container from "../common/Container";
import DataMatrix from "../common/DataMatrix";
import useDisplayMessage from "../common/lib/hooks/useDisplayMessage";
import KaAlert from "../common/lib/KaAlert";
import Row from "../common/Row";
import SlideOutButton from "../common/SlideOutButton";
import SpinnerButton from "../common/SpinnerButton";
import { driverFacingErrorMessage } from "../common/userFacingMessages/userFacingMessages";
import ScanButton from "../dev/ScanButton";
import ScanForFacilityAccessButton from "../dev/ScanForFacilityAccessButton";
import useCancelLoad from "../requests/useCancelLoad";
import { OpenStatus } from "../requests/useUserStatus";

export interface CheckInProps {
  stagedOrderId: string,
  siteId: string,
  orderNumber: string,
  checkedInTrucksQueued?: number,
  openStatus?: OpenStatus,
  closeTime?: string,
  onCancelled: () => void,
  devToolItems?: React.ReactNode,
}

const CheckIn = (props: CheckInProps) => {
  const { _ } = useLingui();
  const sizePx = 320;
  const displayMessage = useDisplayMessage();
  const [truckCounter, setTruckCounter] = useState({ start: 0, end: props.checkedInTrucksQueued ?? 0 });

  const cancelLoadRequest = useCancelLoad(props.stagedOrderId, {
    onSuccess: () => props.onCancelled(),
    onError: (err) => displayMessage.fail(_(driverFacingErrorMessage(err))),
  });

  useEffect(() => {
    if (props.checkedInTrucksQueued != null && truckCounter.end !== props.checkedInTrucksQueued) {
      setTruckCounter({ start: truckCounter.end, end: props.checkedInTrucksQueued });
    }
  }, [props.checkedInTrucksQueued, truckCounter]);

  const checkedInTrucksQueuedIndicator = () => {
    if (props.checkedInTrucksQueued == null) {
      return <div className="spinner-border spinner-border-sm text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    }
    return <>
      <CountUp
        start={truckCounter.start}
        end={truckCounter.end}
        duration={3}
        suffix=" " />
      <Plural
        value={truckCounter.end}
        one="truck scanned in at terminal"
        other="trucks scanned in at terminal" />
    </>
  }

  const renderStatus = () => {
    switch (props.openStatus) {
      case OpenStatus.Open:
      case null:
        return <></>;
      case OpenStatus.Closed:
        return <h2 className="text-danger"><Trans>Closed</Trans></h2>
      case OpenStatus.ClosingSoon:
        return <h2 className="text-danger">{_(msg`Closing at`) + ' ' + props.closeTime}</h2>
    }
  }

  return <Container
    title={_(msg`Check-in Successful`)}
    subtitle={_(msg`Scan at inbound terminal upon arrival`)}
    devToolItems={
      <>
        <ScanForFacilityAccessButton stagedOrderId={props.stagedOrderId} />
        <ScanButton location={'CheckIn'} siteId={props.siteId} stagedOrderId={props.stagedOrderId} />
        {props.devToolItems}
      </>
    }
    actionItems={
      <SlideOutButton
        text={_(msg`Cancel Load`)}
        icon="x-circle"
        data-bs-toggle="modal"
        data-bs-target="#CancelLoadModal" />
    }>
    <div className="container m-auto">
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <Row className="text-center">
        <h2><Trans>Estimated Wait</Trans></h2>
        <h4>{checkedInTrucksQueuedIndicator()}</h4>
      </Row>
      <Row className="text-center mt-4">
        {renderStatus()}
      </Row>
      <Row style={{ height: sizePx }}>
        <div className="position-relative">
          <div className="position-absolute top-0 start-50 translate-middle-x">
            <DataMatrix content={props.stagedOrderId} />
          </div>
        </div>
      </Row>
      <Row className="mt-3 text-center">
        <h2><Trans>Order Number {props.orderNumber}</Trans></h2>
      </Row>

      <div className="modal fade" id="CancelLoadModal" tabIndex={-1} role="dialog" aria-labelledby="cancelLoadLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title" id="cancelLoadLabel"><Trans>Cancel Order</Trans></h5>
            </div>
            <div className="modal-body">
              <Trans>You will no longer be checked in. Do you wish to continue?</Trans>
            </div>
            <div className="modal-footer justify-content-center">
              <button type="button" className="btn btn-white w-35" data-bs-dismiss="modal"><Trans>No</Trans></button>
              <SpinnerButton
                data-bs-dismiss="modal"
                className="btn btn-primary w-35"
                spinning={cancelLoadRequest.isLoading}
                onClick={() => cancelLoadRequest.request()}>
                <Trans>Yes</Trans>
              </SpinnerButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
}

export default CheckIn;
