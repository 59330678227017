export const trackUserSignIn = (id: string, email: string,) => {
  // @ts-ignore
  heap.identify(id);
  // @ts-ignore
  heap.addUserProperties({ email });
}
export const trackEmployeeSignIn = (id: string, email: string) => {
  // @ts-ignore
  heap.identify(id);
  // @ts-ignore
  heap.addUserProperties({ email, kaEmployee: true });
}
export const trackSiteSelected = (id?: string, name?: string) => {
  // @ts-ignore
  heap.addUserProperties({ siteId: id, siteName: name });
}
export const clearTracking = () => {
  // @ts-ignore
  heap.resetIdentity();
}