import { Trans } from '@lingui/macro';
import { useContext, useState } from 'react';
import { usePWAInstall } from 'react-use-pwa-install';
import { User, UserContext, UserStatus } from '../userAccess/UserContext';
import useLocalStorage from './lib/hooks/useLocalStorage';
import { isPwaMode } from './utilities';

const InstallPwaModal = () => {
  const userStatus = useContext<UserStatus>(UserContext);
  const user = userStatus as User;
  const [hasSeenInstallPwaModal, setHasSeenInstallPwaModal] = useLocalStorage("hasSeenInstallPwaModal", false);
  const [showPwaModal, setShowPwaModal] = useState(!hasSeenInstallPwaModal && !isPwaMode() && user.hasAcceptedCurrentUserAgreement);

  const browserPwaInstallPrompt = usePWAInstall();

  const handlePwaInstallDeclined = () => {
    setHasSeenInstallPwaModal(true);
    setShowPwaModal(false);
  };

  const handlePwaInstallAccepted = async () => {
    setHasSeenInstallPwaModal(true);
    setShowPwaModal(false);

     await browserPwaInstallPrompt();
  };

  const installPwaModalContent = () => 
    <>
      <div className="modal-body">
        <Trans>Install LOADPASS as a web app for a full screen experience.</Trans>
      </div>
      <div className="modal-footer justify-content-center">
        <button type="button"
          className="btn btn-white w-35"
          data-bs-dismiss="modal"
          onClick={handlePwaInstallDeclined}>
          <Trans>Cancel</Trans>
        </button>
        <button type="button"
          className="btn btn-primary w-35"
          onClick={handlePwaInstallAccepted}
          data-bs-dismiss="modal">
          <Trans>Install</Trans>
        </button>
      </div>
    </>

  const iosAddToHomeScreenInstructionsModalContent = () => 
    <>
      <div className="modal-body">
        <Trans>Tap</Trans>
        <i className="bi bi-box-arrow-up px-1" style={{ fontSize: "1.75rem", lineHeight: 0 }} />
        <Trans>then "Add to Home Screen" to install LOADPASS as a web app for a full screen experience.</Trans>
      </div>
      <div className="modal-footer justify-content-center">
        <button type="button"
          className="btn btn-primary w-35"
          data-bs-dismiss="modal"
          onClick={handlePwaInstallDeclined}>
          <Trans>Close</Trans>
        </button>
      </div>
    </>

  const genericBrowserAddToHomeScreenModalContent = () =>
    <>
      <div className="modal-body">
        <Trans>Add LOADPASS to the home screen to install it as a web app for a full screen experience.</Trans>
      </div>
      <div className="modal-footer justify-content-center">
        <button type="button"
          className="btn btn-primary w-35"
          data-bs-dismiss="modal"
          onClick={handlePwaInstallDeclined}>
          <Trans>Close</Trans>
        </button>
      </div>
    </>

  const getPwaInstallInstructions = () => {
    if ('BeforeInstallPromptEvent' in window) {
      return installPwaModalContent();
    }

    const isIos = [
      'iPad Simulator', 'iPhone Simulator', 'iPod Simulator',
      'iPad', 'iPhone', 'iPod'
      ].includes(navigator.platform);

    return isIos ?
      iosAddToHomeScreenInstructionsModalContent() :
      genericBrowserAddToHomeScreenModalContent();
  }

  if (!showPwaModal)
    return null;

  return <>
    <div className="modal fade show d-block" id="installPwaModal" tabIndex={-1} role="dialog" aria-labelledby="installPwaModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header justify-content-center">
            <h5 className="modal-title" id="installPwaModalLabel"><Trans>Install LOADPASS App</Trans></h5>
          </div>
          {getPwaInstallInstructions()}
        </div>
      </div>
    </div>
    <div className="modal-backdrop fade show"/>
  </>
}

export default InstallPwaModal;