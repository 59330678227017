import { HttpMethod } from "../common/lib/fetch/useFetch";
import useRequest, { RequestOptions } from "../common/lib/fetch/useRequest";

const useValidateForFacilityAccess = (stagedOrderId: string, siteId: string, options?: RequestOptions) =>
  useRequest({
    headers: () => Promise.resolve(new Headers()),
    method: HttpMethod.POST,
    path: `/api/developer/stagedOrders/${stagedOrderId}/facilityAccess?siteId=${siteId}`
  }, options);

export default useValidateForFacilityAccess;
