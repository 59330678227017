import { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import './custom.css';
import FullscreenLoadingSpinner from './common/FullscreenLoadingSpinner';
import Scale from './dev/Scale';
import AddSite from './routes/AddSite';
import { UserContext, UserState } from './userAccess/UserContext';
import UserFlow from './UserFlow';
import AddSiteAdmins from './routes/AddSiteAdmins';
import { CarrierDriverInfo } from './pages/AdminPortal/CarrierDriverInfo';
import { ScaleAutomation } from './pages/AdminPortal/ScaleAutomation';
import { CustomerDestination } from './pages/AdminPortal/CustomerDestination';
import { BayDirections } from './pages/AdminPortal/BayDirections/BayDirections';
import { SiteRegistration } from './pages/AdminPortal/SiteRegistration';
import { GeneralSettings } from './pages/AdminPortal/GeneralSettings';
import { Dashboard } from './dev/Dashboard';
import { KaEmployeeContext, KaEmployeeState } from './userAccess/kaEmployee/KaEmployeeContext';
import { KaEmployeeContainer } from './dev/KAEmployeeContainer';
import { Anonymous } from './Anonymous';
import { KioskProvider } from './userAccess/kiosk/KioskProvider';
import SiteAdminRequired from './SiteAdminRequired';
import { BayDirectionsValidation } from './pages/AdminPortal/BayDirectionsValidation';
import LoadQuestions from './pages/AdminPortal/LoadQuestions/LoadQuestions';
import SitesPage from './pages/KAEmployeePortal/SitesPage';
import { isProdEnvironment } from './common/utilities';
import { KioskManagement } from './pages/AdminPortal/Kiosks/KioskManagement';
import { LoadConfiguration } from './pages/AdminPortal/LoadConfiguration';
import SafetyMessage from './pages/SafetyMessage';
import { StagedOrders } from './pages/AdminPortal/StagedOrders';
import BusinessHoursPage from './pages/AdminPortal/BusinessHours/BusinessHoursPage';
import { withMonitoring } from './common/lib/Monitoring';
import UserAgreementModal from './UserAgreementModal';

const App = () => 
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<DriverUserRequired><UserFlow /></DriverUserRequired>} />

      <Route path="/AddSite" element={<UserRequired><AddSite /></UserRequired>} />
      <Route path="/addSiteAdmins" element={<UserRequired><AddSiteAdmins /></UserRequired>} />
      {!isProdEnvironment() &&
        <Route path="/scale" element={<UserRequired><Scale /></UserRequired>} />}

      <Route path="/admin" element={<SiteAdminRequired />} />
      <Route path="/carrierDriverSettings" element={<SiteAdminRequired><CarrierDriverInfo /></SiteAdminRequired>} />
      <Route path="/customerDestination" element={<SiteAdminRequired><CustomerDestination /></SiteAdminRequired>} />
      <Route path="/scaleAutomation" element={<SiteAdminRequired><ScaleAutomation /></SiteAdminRequired>} />
      <Route path="/bayDirections" element={<SiteAdminRequired><BayDirections /></SiteAdminRequired>} />
      <Route path="/bayDirectionsValidation" element={<SiteAdminRequired><BayDirectionsValidation /></SiteAdminRequired>} />
      <Route path="/siteRegistration" element={<SiteAdminRequired><SiteRegistration /></SiteAdminRequired>} />
      <Route path="/loadConfiguration" element={<SiteAdminRequired><LoadConfiguration /></SiteAdminRequired>} />
      <Route path="/generalSettings" element={<SiteAdminRequired><GeneralSettings /></SiteAdminRequired>} />
      <Route path="/loadQuestions" element={<SiteAdminRequired><LoadQuestions /></SiteAdminRequired>} />
      <Route path="/kioskManagement" element={<SiteAdminRequired><KioskManagement /></SiteAdminRequired>} />
      <Route path="/stagedOrders" element={<SiteAdminRequired><StagedOrders /></SiteAdminRequired>} />
      <Route path="/businessHours" element={<SiteAdminRequired><BusinessHoursPage /></SiteAdminRequired>} />

      <Route path="/dashboard" element={<KaEmployeeRequired><Dashboard /></KaEmployeeRequired>} />
      <Route path="/sites" element={<KaEmployeeRequired><SitesPage /></KaEmployeeRequired>} />

      <Route path="/anonymous" element={<KioskProvider><Anonymous /></KioskProvider>} />
    </Routes>
  </BrowserRouter>

const UserRequired: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const userStatus = useContext(UserContext);
  
  useEffect(() => {
    if (userStatus.state === UserState.SignedOut) {
      userStatus.signIn();
    }
  }, [userStatus]);

  if (userStatus.state === UserState.Loading) return <FullscreenLoadingSpinner />
  if (userStatus.state === UserState.SignedOut) return <></>;

  return <>
    <UserAgreementModal />
    {children}
  </>;
}

const DriverUserRequired: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const userStatus = useContext(UserContext);

  if (userStatus.state === UserState.Loading) return <FullscreenLoadingSpinner />
  if (userStatus.state === UserState.SignedOut) return <SafetyMessage signedIn={false} ackSafetyMessage={userStatus.signIn}/>;

  return <>
    <UserAgreementModal />
    {children}
  </>;
}

const KaEmployeeRequired: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const userStatus = useContext(KaEmployeeContext);

  if (userStatus.state === KaEmployeeState.Loading) return <FullscreenLoadingSpinner />

  if (userStatus.state === KaEmployeeState.SignedOut) {
    return <KaEmployeeSignIn signIn={userStatus.signIn} />
  }

  return <KaEmployeeContainer>{children}</KaEmployeeContainer>;
}

const KaEmployeeSignIn = ({ signIn }: { signIn: () => void }) =>
  <main id="content" role="main" className="navbar-sidebar-aside-sm">
    <div className="container">
      <nav className="navbar navbar-expand-sm navbar-sidebar navbar-vertical navbar-light bg-white">
        <div className="navbar-brand-wrapper" style={{ height: 'auto' }}>
          <div className="d-flex align-items-center">
            <a className="navbar-brand" href="/" target="_self" rel="noreferrer" aria-label="LP_Logo">
              <img className="navbar-brand-logo" src="/images/lp_logo.png" alt="LOADPASS" width='100' style={{ minWidth: '12rem', maxWidth: '12rem' }} />
            </a>
          </div>
        </div>
        <div className="position-absolute bottom-0 start-0">
          <a className="navbar-brand" href="https://www.kahlerautomation.com" target="_blank" rel="noreferrer" aria-label="KA_Logo">
            <img className="navbar-brand-logo m-2" src="/images/logo.png" alt="Logo" width='100' style={{ minWidth: '15rem', maxWidth: '15rem' }} />
          </a>
        </div>
      </nav>
    </div>
    <div className="px-lg-5 px-xl-10 height-100 d-flex flex-column">
      <div className="mx-auto my-auto">
        <div className="my-auto">
          <h2>Kahler Automation Employee Login Required:</h2>
        </div>
        <div className="mt-4 text-center">
          <button
            className="btn btn-primary "
            onClick={() => signIn()}>
            Sign in as Employee
          </button>
        </div>
      </div>
    </div>
  </main>

export default withMonitoring(App);
