import { useState } from "react";
import Button from "../../../common/Button";
import KaModal from "../../../common/KaModal";
import useDisplayMessage from "../../../common/lib/hooks/useDisplayMessage";
import KaAlert from "../../../common/lib/KaAlert";
import { Bay, DispenseType } from "../../../requests/useUpdateBaySettings";

interface UpdateBayModalProps {
  bay: Bay,
  onUpdate: (dispenseType: DispenseType, bulkReceiving: boolean) => void,
  toggle: () => void,
}

const toBlendBulk = (dispenseType: DispenseType) => {
  switch (dispenseType) {
    case DispenseType.Blended:
      return { blend: true, bulk: false };

    case DispenseType.Bulk:
      return { blend: false, bulk: true };

    case DispenseType.Both:
      return { blend: true, bulk: true }
  }
}

export const UpdateBayModal = ({ bay, onUpdate, toggle }: UpdateBayModalProps) => {
  const displayMessage = useDisplayMessage();
  const [bulk, setBulk] = useState<boolean>(toBlendBulk(bay.dispenseType).bulk);
  const [blend, setBlend] = useState<boolean>(toBlendBulk(bay.dispenseType).blend);
  const [bulkReceiving, setBulkReceiving] = useState<boolean>(bay.bulkReceiving);

  const update = () => {
    if (!bulk && !blend) {
      displayMessage.fail("Bay must be configured to be Bulk or Blend");
    }

    if (bulk && blend) {
      onUpdate(DispenseType.Both, bulkReceiving);
    } else if (bulk) {
      onUpdate(DispenseType.Bulk, bulkReceiving);
    } else if (blend) {
      onUpdate(DispenseType.Blended, bulkReceiving);
    }
  }

  return <KaModal
    isOpen={true}
    toggle={toggle}
    title={"Bay Prioritization"}
    body={(<>
      <KaAlert displayMessage={displayMessage.message} onClose={displayMessage.clear} />
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={bulk}
          onChange={() => setBulk(!bulk)}
          id="cbxBulk" />
        <label className="h5" htmlFor="cbxBulk">
          Bulk Orders
        </label>
      </div>
      <div className="ms-4">
        <p>
          Bulk orders will be directed to "Bulk" bays that have all their products available.
          Bulk orders will never be assigned to "Blend" bays.
          If multiple bulk bays can handle the order, then the bay that was used the longest ago will be used.
        </p>
        <p>
          If no "Bulk" bay has the products needed, then the order will be assigned to a "Bulk & Blend" bay.
        </p>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={blend}
          onChange={() => setBlend(!blend)}
          id="cbxBlend" />
        <label className="h5" htmlFor="cbxBlend">
          Blend Orders
        </label>
      </div>
      <div className="ms-4">
        <p>
          Blend orders will be directed to any "Blend" or "Bulk & Blend" bay that has all the products on the order.
          If multiple bays can handle the order, then the bay that was used the longest ago will be used.
        </p>
      </div>
      {/*<div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={bulkReceiving}
          onChange={() => setBulkReceiving(!bulkReceiving)}
          id="cbxBulkReceiving" />
        <label className="h5" htmlFor="cbxBulkReceiving">
          Bulk Receiving
        </label>
      </div>
      <div className="ms-4">
        <p>
          When a PO number is entered during check-in, and the driver proceeds, the system will prioritize bays designated exclusively for Bulk Receiving. 
          As a result, bays allocated for Bulk/Blend dispensing will not be considered for this prioritization and will remain unavailable for Bulk Receiving operations.
        </p>
      </div>*/}
    </>)}
    footer={(<>
      <button type="button" className="btn btn-white" onClick={toggle}>
        Close
      </button>
      <Button onClick={update}>
        Update
      </Button>
    </>)}
  />
}