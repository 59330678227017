import React from 'react'
import { KaEmployeeContext, KaEmployeeState, KaEmployeeStatus } from './KaEmployeeContext';
import { MsalConfiguration, MsalUser, useMsalAuth } from '../../common/lib/hooks/msal/useMsalAuth';
import { clearTracking, trackEmployeeSignIn } from '../tracking';

type Props = {
  config: MsalConfiguration;
  children: React.ReactNode;
}

export const KaEmployeeProvider: React.FC<Props> = ({ children, config }) => {
  const msalAccount = useMsalAuth(config);

  const fetchHeaders = async (user: MsalUser) => {
    if (user.expirationTime <= (Date.now() / 1000)) {
      user.signOut({ postLogoutUrl: window.location.pathname });
      return new Headers();
    }

    return new Headers({ 'Authorization': `Bearer ${(await user.getToken())}` });
  }

  const logout = (user: MsalUser) => { 
    clearTracking();
    user.signOut({ postLogoutUrl: window.location.pathname });
  }

  function toUserStatus(): KaEmployeeStatus {
    if (msalAccount.busy) return { state: KaEmployeeState.Loading };

    if (msalAccount.user == null) return { state: KaEmployeeState.SignedOut, signIn: msalAccount.signIn };

    trackEmployeeSignIn(msalAccount.user.id, msalAccount.user.email);

    return {
      id: msalAccount.user.id!,
      email: msalAccount.user.email!,
      expirationTime: msalAccount.user.expirationTime,
      state: KaEmployeeState.SignedIn,
      fetchHeaders: () => fetchHeaders(msalAccount.user!),
      signOut: () => logout(msalAccount.user!)
    };
  }

  return (
    <KaEmployeeContext.Provider value={toUserStatus()}>
      {children}
    </KaEmployeeContext.Provider>
  );
};
